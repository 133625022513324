import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import { object, string, setLocale, array } from "yup";

import { createCustomer, getUser, updateCustomer } from "services/api";
import { MySpinner } from "components/MySpinner";
import { dateFormat, priceFormat } from "utils/format";
import { Contracts } from "features/Users/Contracts";
import { Helmet } from "react-helmet";
import { Addresses } from "features/Users/Addresses";
import { MainForm } from "features/Users/MainForm";
import { LegalForm } from "features/Users/LegalForm";
import { HeaderPage } from "components/HeaderPage";
import { ActiveResource } from "components/ActiveResource";
import { CustomerInfo } from "features/Users/CustomerInfo";
import { SOURCES_USERS } from "pages/users";
import { locale } from "utils/validationLocale";
import { scrollToInvalid } from "utils/scrollToInvalid";
import { ErrorListener } from "components/ErrorListener";
import { Accounts } from "features/Users/Accounts";
import { Password } from "features/Users/Password";

import "./user.scss";
import { Emails } from "features/Users/Emails";

setLocale(locale);

const CustomerLegalSchema = object().shape({
  firstName: string().required(),
  email: string().email().required(),
  phone: string().required(),
  orgInn: string().required(),
  orgKpp: string().required(),
  orgName: string().required(),
  orgNameShort: string().required(),
  orgOgrn: string().required(),
  orgAddress: string().required(),
  orgRs: string().required(),
  orgKs: string().required(),
  orgBik: string().required(),
  orgBank: string().required(),
  genDir: string().required(),
  genDirGenitive: string().required(),
  typology: string().required()
});

const CustomerLegalSchema2 = object().shape({
  email: string().email().required(),
  phone: string().required(),
  orgInn: string().required(),
  orgKpp: string().required(),
  orgName: string().required(),
  orgNameShort: string().required(),
  orgOgrn: string().required(),
  orgAddress: string().required(),
  orgRs: string().required(),
  orgKs: string().required(),
  orgBik: string().required(),
  orgBank: string().required(),
  genDir: string().required(),
  genDirGenitive: string().required(),
  typology: string().required()
});

const CustomerPrivateSchema = object().shape({
  firstName: string().min(2).required(),
  email: string().min(2).email().required(),
  phone: string().required()
});

export const UserOnePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [customer, setCustomer] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAndSetCustomer = () => {
    if (!id) return;
    setLoading(true);
    getUser(id)
      .then((res) => {
        setCustomer({
          ...res,
          admin: { label: res.admin?.name, value: res.admin?.id },
          salesRep: { label: res.salesRep?.name, value: res.salesRep?.id }
        });
        setInfo(res.info);
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetCustomer();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const submitHandler = (values, resetForm, formkBag) => {

    setLoading(true);

    if (values.id) {
      updateCustomer({
        ...values,
        info,
        admin: values?.admin?.value,
        salesRep: values?.salesRep?.value
      })
        .then((res) => {
          toast.success("Изменения успешно сохранены");
          setCustomer({
            ...res,
            admin: { label: res.admin?.name, value: res.admin?.id },
            salesRep: { label: res.salesRep?.name, value: res.salesRep?.id }
          });
          setInfo(res.info);
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    } else {
      createCustomer({ ...values, info, admin: values?.admin?.value })
        .then((res) => {
          toast.success("Клиент успешно создан");
          setCustomer({
            ...res,
            admin: { label: res.admin?.name, value: res.admin?.id },
            salesRep: { label: res.salesRep?.name, value: res.salesRep?.id }
          });
          setInfo(res.info);
          history.push(res.id);
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const sourceRender = () => {
    const source = SOURCES_USERS.find((source) => source.value === customer.source);
    return source?.label || "Менеджер";
  };

  const renderHeaderName = () => {
    if (!loading && !customer?.id) {
      return "Новый клиент";
    }
    return customer?.name;
  };

  const checkboxHandler = (e) => {
    const u = { ...customer };
    u[e.target.name] = e.target.checked;

    setCustomer(u);
  };

  const scrollToTop = (val) => {
    //  console.log(val);
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const customerMainHandler = (data, setFieldValue) => {

    setFieldValue("customerMain", data.id);
    setFieldValue("orgNameShort", data.orgNameShort);
    setFieldValue("orgName", data.orgName);
    setFieldValue("orgAddress", data.orgAddress);
    setFieldValue("orgInn", data.orgInn);
    setFieldValue("orgKpp", data.orgKpp);
    setFieldValue("orgOgrn", data.orgOgrn);
    setFieldValue("orgOkpo", data.orgOkpo);
    setFieldValue("genDir", data.genDir);
    setFieldValue("firstName", data.firstName);
    setFieldValue("lastName", data.lastName);
    setFieldValue("middleName", data.middleName);
  };


  return (
    <Container fluid className="user p-0">
      <Helmet>
        <title>{renderHeaderName()}</title>
      </Helmet>

      {loading && <MySpinner overlay />}

      <Formik
        initialValues={{
          id: customer?.id || "",
          createdAt: customer?.createdAt || "",
          active: customer ? customer?.active : false,
          branch: customer?.branch || [],
          customerMain: customer?.customerMain || null,
          admin: customer?.admin || null,
          salesRep: customer?.salesRep || null,
          lastName: customer?.lastName || "",
          firstName: customer?.firstName || "",
          middleName: customer?.middleName || "",
          email: customer?.email || "",
          phone: customer?.phone || "",
          comment: customer?.comment || "",
          type: customer?.type || 2,
          markup: customer?.markup || "",
          delayDayCount: customer?.delayDayCount || 0,
          creditSumMax: customer?.creditSumMax || 0,
          orgName: customer?.orgName || "",
          orgNameShort: customer?.orgNameShort || "",
          orgAddress: customer?.orgAddress || "",
          consigneeAddress: customer?.consigneeAddress || "",
          consigneeName: customer?.consigneeName || "",
          orgInn: customer?.orgInn || "",
          orgKpp: customer?.orgKpp || "",
          orgOgrn: customer?.orgOgrn || "",
          orgOkpo: customer?.orgOkpo || "",
          orgRs: customer?.orgRs || "",
          orgKs: customer?.orgKs || "",
          orgBik: customer?.orgBik || "",
          orgBank: customer?.orgBank || "",
          genDir: customer?.genDir || "",
          genDirGenitive: customer?.genDirGenitive || "",
          post: customer?.post || "",
          withDiadoc: customer?.withDiadoc || false,
          diadocRecepientId: customer?.diadocRecepientId || "",
          wholesale: customer?.wholesale || false,
          typology: customer?.info?.typology || null,
          accordanceDocument: customer?.accordanceDocument || null,
          emailsForDocuments: customer?.emailsForDocuments || [],
          queryLimit: customer?.queryLimit || 0,
          printDoc: customer?.printDoc || []
        }}
        validate={(values) => {
          try {
            if (values.type === 2) {
              if (values.id) {
                validateYupSchema(values, CustomerLegalSchema2, true, values);
              } else {
                validateYupSchema(values, CustomerLegalSchema, true, values);
              }

            } else {
              validateYupSchema(values, CustomerPrivateSchema, true, values);
            }
          } catch (err) {

            // console.log(err);
            //   scrollToInvalid();
            return yupToFormErrors(err);
          }
          return {};
        }}
        onSubmit={(values, { setSubmitting, resetForm }, formikBag) => {

          submitHandler(values, resetForm, formikBag);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (

          <form onSubmit={handleSubmit}>
            <ErrorListener onError={scrollToTop} />

            <HeaderPage title={renderHeaderName()}>
              <>
                {customer?.id && (
                  <>
                    <ActiveResource
                      active={values.active}
                      checkboxHandler={e => setFieldValue("active", e.target.checked)}
                    />

                    <div className="user__balance">
                      Баланс:{" "}
                      <span className={classNames({ red: customer.balance < 0 })}>
                        {priceFormat(customer.balance)} р.
                      </span>
                    </div>
                    <div>
                      <div>Дата регистрации: {dateFormat(customer.createdAt)}</div>
                      <div>Источник: {sourceRender()}</div>
                    </div>
                  </>
                )}
              </>
            </HeaderPage>

            <MainForm
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setLoading={setLoading}
            />
            {customer?.id &&
              <Emails
                customer={customer}
                setLoading={setLoading}
              />
            }

            {values && <CustomerInfo
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              info={info}
              setInfo={setInfo}
            />}

            {values?.type === 2 && (
              <LegalForm
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setLoading={setLoading}
              />
            )}

            {values.id && (
              <Accounts getAndSetCustomer={getAndSetCustomer} customer={customer} />
            )}

            <div className="user__save">
              <Button
                type="submit"
                color="primary"
                className="button-wide"
                onClick={scrollToInvalid}
              >
                Сохранить
              </Button>
            </div>

          </form>
        )}
      </Formik>

      {customer?.id && (
        <>
          <Addresses customer={customer} />
          {customer.type === 2 && <Contracts customer={customer} />}
          <Password user={customer} />
        </>
      )}
    </Container >
  );
};
